import {modalGeneric} from "./common";

export default class manageData {

    static uniqueFields = {
        'licence_number': 'Licence Number',
        'digicard_number': 'Digicard Number',
    };

    static licenceNumberLength = {
        'uk': 16,
        'ni': 8,
        'eu': null,
    };

    static cleanCardNumber(value)
    {
        return value.toUpperCase().trim().replaceAll(' ', '');
    }

    static checkUniqueData($trs, $el, field, id)
    {
        const cardNumber = this.cleanCardNumber($el.val());
        let onscreenClashes = [];

        // check against existing
        const operatorClashes = app.OPTIONS.unique[field].filter(r => {
            return id != r.id && r[field] === cardNumber;
        });

        // check against onscreen clashes
        if( !operatorClashes.length ) {
            $trs.not(`[data-id="${id}"]`).find(`[data-field="${field}"]`).each( (k, el) => {
                if( this.cleanCardNumber(el.value) === cardNumber ) {
                    onscreenClashes.push(k);
                }
            });
        }

        if( operatorClashes.length || onscreenClashes.length ){
            modalGeneric(`${this.uniqueFields[field]} already exists`, `This ${this.uniqueFields[field]} (${cardNumber}) is already registered to a driver, please enter a unique value`);
            $el.addClass('error');
        }
    }

    static licenceNumberRegion($licenceRegion)
    {
        $licenceRegion.on('change', e =>{
            const $el = $(e.currentTarget);
            const region = $el.val();
            const $licence = $el.closest('td').prev().find('[data-field="licence_number"]');
            const length = ( region ) ? this.licenceNumberLength[region] : 0;
            const title = ( length ) ? `Licence number should be ${length} characters in length` : '';

            if( region ) {
                $licence.attr('minlength', this.licenceNumberLength[region]).attr('maxlength', this.licenceNumberLength[region]);
                if( title ){
                    $licence.attr('title', title);
                } else {
                    $licence.removeAttr('title');
                }
            } else {
                $licence.removeAttr('minlength').removeAttr('maxlength').removeAttr('title');
            }

        }).trigger('change');
    }

    static highlightEmptyInputs($inputs, $trs)
    {
        $inputs.on('change init', e => {
            const $el = $(e.currentTarget);
            const tag = $el[0].tagName.toLowerCase();
            const field = $el.attr('data-field');
            let value;

            if (tag === 'select') {
                const selectedValue = $el.find('option:selected').val();
                value = (selectedValue) ? true : false;
            } else {
                value = ($el.val()) ? true : false;
            }

            if (value) {
                $el.removeClass('empty');
            } else {
                $el.addClass('empty');
            }

            if (e.type === 'change' && this.uniqueFields[field] && $el.val() ) {
                this.checkUniqueData($trs, $el, field, $el.closest('tr').attr('data-id'));
            }
        }).trigger('init');
    }
}